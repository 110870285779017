import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DownloadService} from '../../../../@core/download.service';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {take} from 'rxjs/operators';
import {RequestHandler} from '../../../../@core/utils/request-handler';

@Component({
    selector: 'app-embed-component',
    templateUrl: './embed.component.html',
    styleUrls: ['./embed.component.scss'],
})
export class EmbedComponent extends UnsubscribeComponent implements AfterViewInit {
    @Input() url: string;
    @Input() mimeType: string;
    // this is for the case when we need to download the file first and then pass to the embed element, e.g. pdf and most of the files except CCDA documents
    @Input() needDownload = false;
    // this is for the case when we want to offer the opportunity to download the file and the browser built-in file viewer doesn't have the download option,
    // whick means everything execpt pdf file
    @Input() showDownloadBtn = false;
    // this is for the case like lab test attachments, where we want to offer the opportunity to toggle the file viewer
    @Input() showToggleBtn = false;
    // this is for the case when we want to show the file in the slide panel
    @Input() showInSlidePanel = true;
    @Input() panelInfo: string;

    objectUrl: string;
    show = true;
    downloadError: any;

    downloadRequest = new RequestHandler(
        () => this.downloadService.downloadByUrl(this.url),
        {
            destroy$: this.destroy$,
            cancel$: this.cancelRequest$,
        }
    );

    @ViewChild('iframe') iframe: ElementRef<HTMLObjectElement>;

    constructor(private downloadService: DownloadService) {
        super();
    }

    get isImage() {
        return this.mimeType?.startsWith('image/');
    }

    ngAfterViewInit() {
        if (this.mimeType === 'application/pdf') {
            this.showDownloadBtn = false;
        }
        if (this.needDownload) {
            this.downloadRequest.call().subscribe({
                next: res => {
                    if (!this.mimeType) {
                        const ext = res.cdp.split('.')[1];
                        if (ext === 'pdf') {
                            this.mimeType = 'application/pdf';
                        } else if (ext === 'html') {
                            this.mimeType = 'text/html';
                        } else {
                            this.mimeType = `image/${ext}`;
                        }
                    }
                    this.objectUrl = URL.createObjectURL(new Blob([res?.binary ?? res], {type: this.mimeType}));
                },
                error: err => {
                    this.downloadError = err;
                },
            });
        } else {
            this.objectUrl = this.url;
        }
    }

    compOnDestroy() {
        if (this.objectUrl) URL.revokeObjectURL(this.objectUrl);
    }

    downloadAttachment() {
        this.downloadService.downloadFileByUrl(this.url).pipe(take(1));
    }

    toggle() {
        this.show = !this.show;
    }
}
