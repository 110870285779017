<ng-container *ngIf="!downloadRequest.isPending; else loading">
    <div [ngClass]="{'panel-header': showInSlidePanel}">
        <div *ngIf="showInSlidePanel"
             class="panel-title">
            Document Viewer
            <ng-container *ngIf="panelInfo">
                <div class="panel-title-info">
                    {{panelInfo}}
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="!downloadError">
            <div *ngIf="showDownloadBtn"
                 class="add-on">
                <button class="btn btn-outline-primary"
                        (click)="downloadAttachment()">
                    <fal i="download" class="icon"></fal>
                    Download
                </button>
            </div>
            <fal *ngIf="showToggleBtn"
                 [i]="show ? 'chevron-up' : 'chevron-down'"
                 (click)="toggle()"
                 class="ml-2 cursor-pointer">
            </fal>
        </ng-container>
    </div>
    <ng-container *ngIf="!downloadError; else errorTmp">
        <div *ngIf="objectUrl && !isImage && mimeType"
             [class.mh-100]="show"
             [class.mh-0]="!show"
             class="embed-container">
            <embed [src]="objectUrl|safe"
                   [type]="mimeType">
        </div>
        <img *ngIf="isImage"
             [src]="objectUrl|safe"
             [class.mh-100]="show"
             [class.mh-0]="!show"
             style="max-width: 100%;">
    </ng-container>
</ng-container>
<ng-template #loading>
    <div [ngClass]="{'panel-body': showInSlidePanel}">
        <app-spinner></app-spinner>
    </div>
</ng-template>

<ng-template #errorTmp>
    <div [ngClass]="{'panel-body': showInSlidePanel}">
        <app-illustration-message [title]="'Error'"
                                  [circleColor]="['danger'|color, .33]"
                                  iconName="times"
                                  caption="An error occurred during download. Please try again.">
        </app-illustration-message>
    </div>
</ng-template>
